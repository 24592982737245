@import '@/scss/variables.scss'
@import '~vuetify/src/styles/styles.sass'


.page__buttons
  margin: 40px 0

  .flex
    display: flex
    justify-content: center
